import React from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import aboutUsImage from "../assets/img/about-us.jpg";

const AboutUs = () => {
  return (
    <section className="aboutus">
      <Container>
        <h2>About Us</h2>
        <Row>
          <div className="col-md-6">
            <p>
              Welcome to CodeForgeSolutions! We are a one-man software
              development team with a strong focus on creating innovative and
              user-friendly websites and web applications. Our founder, a solo
              software developer, holds a Bachelor's degree in Computer Science
              and has years of experience in the field.
            </p>
            <p>
              Our goal is to provide our clients with high-quality, cutting-edge
              solutions that meet their specific needs and exceed their
              expectations. We specialize in custom web development, from the
              initial concept to the final product. Our expertise in the latest
              technologies and trends allows us to deliver solutions that are
              not only visually appealing but also functional and efficient.
            </p>
            <p>
              In addition to our web development services, we also offer
              consulting services to help our clients navigate the ever-changing
              digital landscape.
            </p>
            <p>
              We look forward to the opportunity to work with you and create a
              website or web application that exceeds your expectations.
            </p>
          </div>

          <div className="col-md-6">
            <Container id="about-us-img ">
              <img src={aboutUsImage} />
            </Container>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;

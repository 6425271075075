import webDevImg from "../assets/img/web-development.png";
import softwareImg from "../assets/img/software.png";
import webSolutionImg from "../assets/img/web-solutions.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Services = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="services">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Services</h2>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={webDevImg} alt="Image" />
                  <h5>WebSite Development</h5>
                  {/* <ul>
                    <li>Custom Web Development</li>
                    <li>Website Redesign</li>
                    <li>Website Maintenance</li>
                  </ul> */}
                </div>
                <div className="item">
                  <img src={softwareImg} alt="Image" />
                  <h5>Android Development</h5>
                </div>
                <div className="item">
                  <img src={webSolutionImg} alt="Image" />
                  <h5>Web Consulting</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

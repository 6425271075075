import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/ct-catering.jpg";
import projImg2 from "../assets/img/los-altos.jpg";
import projImg3 from "../assets/img/screen-locker.png";

import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      description: "CT Catering",
      imgUrl: projImg1,
      link: "https://cheftablecatering.com/",
    },
    {
      description: "Los Altos Jalisco Resturant",
      imgUrl: projImg2,
      link: "https://www.altosjaliscos.com/",
    },
    {
      description: "Screen Locker: One Tap",
      imgUrl: projImg3,
      link: "https://play.google.com/store/apps/details?id=com.jl.by.apps.screenlocker&hl=en_US&gl=US",
    },
  ];

  return (
    <section className="project" id="projects ">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div>
                  <h2>Projects</h2>
                  <p>Samples of our previous works</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Tab.Content id="slideInUp">
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img className="background-image-right" src={colorSharp2}></img> */}
    </section>
  );
};
